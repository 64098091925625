<template>
  <div class="transition_modal">
    <b-modal v-model="visible" v-if="visible" v-on="$listeners" size="lg" hide-header-close
             header-class="justify-content-center" @ok.prevent="submitModal">

      <template #modal-title>
        <h1>{{ title || $t('transitions.add') }}</h1>
      </template>

      <template #default="{ ok }">
        <b-form ref="form" @submit.prevent="ok">
          <ul class="list-group">
            <li class="list-group-item">
              <h4>{{ $t("transitions.details") }}</h4>
            </li>
            <li class="list-group-item" v-for="list in inputRows"
                v-if="list.find(input => input.visible===undefined || input.visible())">
              <b-row align-v="center">
                <b-col v-for="input in list" v-bind="$compute(input.col)" v-if="input.visible===undefined || input.visible()">
                  <generic-input v-bind="input" v-model="record[input.key]"/>
                </b-col>
              </b-row>
            </li>
          </ul>

          <!-- vehicles -->
          <ul class="list-group m-t-md">

            <li class="list-group-item">
              <b-row>
                <b-col>
                  <h4>
                    {{ $t("multiples.vehicle.one") }}
                  </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-for="input in vehicleInputList" v-bind="$compute(input.col)" v-if="input.visible===undefined || $compute(input.visible)">
                  <generic-input v-bind="input" v-model="record[input.key]" @onSelected="input.onSelected || (()=>{})"/>
                </b-col>
              </b-row>
            </li>
          </ul>
        </b-form>
      </template>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm" variant="default" @click="cancel">{{ $t('button.back') }}</b-button>
          <b-button size="sm" variant="primary" type="submit" @click.prevent="$refs.form.requestSubmit()">
            {{ $t('button.save') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {getTransition, postTransition, putTransition, restoreTransition} from "@/services/transitions";
import {getSelectableClusters} from "@/services/clusters";
import {getSelectableVehicleClasses} from "@/services/vehicle_classes";
import {getSelectableEngines, getSelectableVehicles} from "@/services/vehicles";
import SearchableSelect from "@/components/searchable_select";
import Datepicker from "@/components/datepicker";
import GenericInput from "@/components/generic_input";
import {mapGetters} from "vuex";

export default {
  name: "TransitionModal",
  components: {GenericInput, Datepicker, SearchableSelect},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    },
    type: {
      type: String,
      default: 'create'
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      record: this.recordDefaultState(),
      inputRows: [
        [
          {
            key: 'start_cluster_id',
            label: 'common.start_city',
            placeholder: 'selector.select_cluster',
            type: 'select',
            required: true,
            fetchRecords: this.fetchClusters,
            disabled: () => {
              return !this.checkAllowedTypes(['create'])
            }
          },
          {
            key: 'end_cluster_id',
            label: 'common.end_city',
            placeholder: 'selector.select_cluster',
            type: 'select',
            required: true,
            fetchRecords: this.fetchClusters,
            disabled: () => {
              return !this.checkAllowedTypes(['create'])
            }
          }
        ],
        [
          {
            key: 'start_date',
            label: ['common.start_date_day', 'common.start_date_time'],
            type: 'datepicker',
            required: true,
            disabled: () => {
              return !this.checkAllowedTypes(['create', 'edit'])
            }
          },
          {
            key: 'end_date',
            label: ['common.end_date_day', 'common.end_date_time'],
            type: 'datepicker',
            required: true,
            disabled: () => {
              return !this.checkAllowedTypes(['create', 'edit', 'restore'])
            }
          }
        ],
        [
          {
            key: 'delivery_in_opening_hours',
            label: 'common.delivery_in_opening_hours',
            type: 'checkbox',
            required: true,
            disabled: () => {
              return !this.checkAllowedTypes(['create', 'edit'])
            },
          }
        ],
        [
          {
            key: 'latest_reservation_date',
            label: ['common.latest_reservation_date_day', 'common.latest_reservation_date_time'],
            type: 'datepicker',
            required: true,
            disabled: () => {
              return !this.checkAllowedTypes(['create', 'edit', 'restore'])
            }
          }
        ]
      ],
      vehicleInputList: [
        {
          key: 'vehicle_class_id',
          label: 'multiples.vehicle_class.one',
          placeholder: 'selector.select_vehicle_class',
          type: 'select',
          required: true,
          fetchRecords: getSelectableVehicleClasses,
          col: { cols: 12 },
          onSelected: (record) => {
            if (record && record.vehicle_id > 0 && this.record.vehicle_id <= 0) {
              this.record.vehicle_id = record.vehicle_id
            }
          },
          disabled: () => {
            return !this.checkAllowedTypes(['create'])
          },
        },
        {
          key: 'vehicle_id',
          label: 'vehicles.name',
          placeholder: 'selector.select_vehicle',
          type: 'select',
          required: true,
          fetchRecords: getSelectableVehicles,
          disabled: () => {
            return !this.checkAllowedTypes(['create'])
          },
          onSelected: () => null
        },
        {
          key: 'engine',
          label: 'common.engine',
          placeholder: 'selector.select_engine',
          type: 'select',
          fetchRecords: getSelectableEngines,
          disabled: () => {
            return !this.checkAllowedTypes(['create'])
          },
          required: true
        },
        {
          key: 'contingent',
          label: 'common.contingent',
          type: 'number',
          min: 0,
          max: 999,
          types: [],
          required: true,
          disabled: () => {
            return !this.checkAllowedTypes(['create', 'edit'])
          }
        },
        {
          key: 'notes',
          label: 'common.notes',
          placeholder: 'transfers.additional_notes',
          col: { cols: 12 },
          disabled: () => {
            return !this.checkAllowedTypes(['create', 'edit', 'restore'])
          }
        }
      ],
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value) {
          if (this.id > 0) this.record = await getTransition(this.id) // TODO: catch
          else this.record = this.recordDefaultState()
        }
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"])
  },
  methods: {
    recordDefaultState() {
      return {
        start_cluster_id: 0,
        end_cluster_id: 0,
        start_date: new Date(),
        end_date: new Date(),
        latest_reservation_date: new Date(),
        delivery_in_opening_hours: true,
        vehicle_id: 0,
        vehicle_class_id: 0,
        contingent: 0
      }
    },
    checkAllowedTypes(types) {
      return types.includes(this.type)
    },
    async submitModal() {
      let promise = null;
      if (this.checkAllowedTypes(['create'])) {
        promise = postTransition(this.record)
      } else if (this.checkAllowedTypes(['restore'])) {
        promise = restoreTransition(this.id, this.record)
      } else {
        promise = putTransition(this.id, this.record)
      }
      promise.then((response) => {
        this.visible = false
      })
    },
    async fetchClusters(filterQuery) {
      return getSelectableClusters(filterQuery)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>